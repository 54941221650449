import React from "react"
import { Link, graphql } from "gatsby"
import SEO from "../../components/seo-rando"
import Layout from "../../components/layout"
import "../../styles/main.scss"
import "../../styles/voyages.scss"
import SnapImg from "../../components/snapimg"

const TravelPage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Trekathlon"
        descriptiom="Liste des voyages réalisés"
        keywords={[
          `Trekking`,
          `Travel`,
          `Tips`,
          `Budget`,
          `Photography`,
          `Travel`,
        ]}
        meta={[{ name: "robots", content: "noindex" }]}
      />
      <section class="travellist">
        <div class="counter">
          {data.allMarkdownRemark.totalCount} voyages répertoriés
        </div>
        <div class="group">
          {data.allMarkdownRemark.edges.map(({ node }) => (
            <Link to={node.fields.slug}>
              <div class="boxtravel" key={node.id}>
                <SnapImg
                  filename={node.frontmatter.picturemenu}
                  alt={node.frontmatter.shortdesc}
                />
              </div>
            </Link>
          ))}
        </div>
      </section>
    </Layout>
  )
}

export default TravelPage

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { section: { eq: "travel" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            section
            category
            name
            shortdesc
            picturemenu
            published(formatString: "YYYY/MM/DD")
            date
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
