import Img from "gatsby-image"
import { graphql, StaticQuery } from "gatsby"
import React from "react"
import style from "../styles/snapimg.module.scss"

const SnapImg = props => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                sizes(maxWidth: 800) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.images.edges.find(n => {
        return n.node.relativePath.includes(props.filename)
      })
      if (!image) {
        return null
      }

      const imageSizes = image.node.childImageSharp.sizes
      const angle = Math.floor(Math.random() * 25 - 13)
      const order = Math.floor(Math.random() * 100)
      return (
        <div
          class={style.box}
          style={{
            transform: "rotate(" + angle + "deg)",
            zIndex: order,
            //width: props.size,
          }}
        >
          <figure>
            <Img alt={props.alt} sizes={imageSizes} />
            <figcaption>{props.alt}</figcaption>
          </figure>
        </div>
      )
    }}
  />
)

export default SnapImg
